import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../../components/structure';
import { Content, Hero } from "../../../components/blocks"
import styled, { css } from "styled-components"
import { brand, font, mq } from "../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";


const Header = styled.div`


${ font.imports.sherika }

  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-top 80px;

  h2 {
    font-family: ${font.family.sherika};
    font-size: 56px;
    font-weight: 700;
    padding: 20px 0;
    color: ${brand.colors.pg_primary_dark};
    display: inline-block;
    
    @media only screen and ${mq.maxMd} {
      text-align: center;
      font-size: 42px;
    }

    .color-emphasis {
      color: ${brand.colors.pg_orange};
    }
  }

}
`;

const Copy = styled.div`
    margin-top: 50px;
    margin-bottom: 20px;
  p,ul {
    color: ${brand.colors.pg_primary_dark};
    font-size: 22px;
  } 
`;

const PrivacyPolicy = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: auto;
`
const PrivacyPage = () => {


  return(
    <Layout>
    <SEO title="Règles de Confidentialité | Plusgrade" 
          description={'Plusgrade - Règles de Confidentialité'}
      />
      <Content whiteBG noMargin>
        <PrivacyPolicy>
          <Header>
              <h2>Plusgrade<span className={"color-emphasis"}> - Politique sur la protection des renseignements personnels</span></h2>
          </Header>
          <Copy>
            <p><strong>Date d’entrée en vigueur:</strong> Novembre 4, 2023</p>
            <p>
            Cette politique sur la protection des renseignements personnels explique quelles données nous collectons auprès des visiteurs du site web Plusgrade www.plusgrade.com (y compris tous les sous-domaines, le « Site ») et/ou de nos services et dans le cadre de nos événements, activités de vente et de marketing, ainsi que la manière dont nous traitons et protégeons les données personnelles et vos droits et choix en ce qui concerne vos données personnelles en notre qualité de contrôleur des données.
            </p>
            
            <p>Dans la présente Politique sur la protection des renseignements personnels: </p>
            
            <ul>
            <li>les « données personnelles » comprennent les informations définies comme des « données personnelles » ou des « informations personnelles » en vertu des lois applicables sur la protection des renseignements personnels. Des informations globales et/ou dépersonnalisées qui ne peuvent pas être associées à une personne identifiable ou conduire à l’identification d’une personne ne sont pas considérées comme des données personnelles; et</li>
            <li>« personne concernée par les données » désigne une personne identifiable à laquelle les données personnelles se rapportent directement ou indirectement.</li>
            </ul>
            <p>Veuillez lire attentivement cette politique sur la protection des renseignements personnels pour comprendre nos pratiques de collecte, d’utilisation et de divulgation de vos données personnelles. Si vous n’êtes pas d’accord avec nos pratiques, le choix est le vôtre de ne pas utiliser notre Site ou nos services. En accédant ou en utilisant ce Site ou nos services, lorsque votre compréhension, votre acceptation ou votre consentement sont requis en vertu des lois applicables sur la protection des renseignements personnels, vous indiquez que vous comprenez, acceptez et consentez aux pratiques décrites dans la présente politique.</p>
            <p>Cliquez sur l’un des liens ci-dessous pour lire un résumé du sujet. Si vous souhaitez en savoir plus, cliquez sur « plus » dans cette section.</p>
            
            <div className="FAQ-expandable-container">
        {/** Question # 1 */}
              <QuestionSection 
                question = {"Quel est le but de cette politique sur la protection des renseignements personnels?"}
                answer={
                  <div>
                    <p>
                    Plusgrade fournit un produit et des services logiciels (ensemble, les « services ») qui permettent aux entreprises de l’industrie du voyage (c.-à-d. les « partenaires commerciaux de Plusgrade) d’offrir à leurs clients (« voyageurs ») la possibilité de surclassement de leur expérience de voyage, sous réserve de la discrétion individuelle de chaque partenaire commercial et de la disponibilité de l’offre.</p>
                    <p>
                    La protection de la vie privée est importante pour nous et pour nos partenaires commerciaux. C’est pourquoi nous nous engageons à assurer la sécurité et la confidentialité des données personnelles que nous recevons et traitons de nos partenaires commerciaux dans le cadre des services que nous leur fournissons. Nous nous engageons également à fournir un produit logiciel qui répond aux meilleures pratiques de l’industrie et aux exigences réglementaires en matière de protection des renseignements personnels et de protection des données.</p>
                    <p>
                    Le but de cette politique sur la protection des renseignements personnels est d’informer les partenaires commerciaux de Plusgrade sur nos pratiques en matière de protection des renseignements personnels et de traitement des données. Cette politique sur la protection des renseignements personnels s’applique également aux données que nous collectons auprès des visiteurs du site et dans le cadre de nos événements et de nos activités de vente et de marketing.</p>
                  </div>
                }

                learnMoreContent={
                  <div>
                    <p>
                    Cette politique sur la protection des renseignements personnels explique comment Plusgrade traite vos données personnelles et les protège dans le cadre de ses activités et de vos droits.</p>
                    <p>
                    Les informations contenues dans cette politique sur la protection des renseignements personnels comprennent:
                    </p>
                    <ul>
                      <li>les coordonnées de Plusgrade, y compris celles de son délégué à la protection des données et de son représentant dans l’Union européenne;</li>
                      <li>les finalités pour lesquelles nous traitons les données personnelles et nos bases juridiques pour le traitement;</li>
                      <li>les types de données personnelles que nous traitons;</li>
                      <li>les catégories de tiers à qui nous pouvons divulguer les données;</li>
                      <li>les compétences dans lesquelles nous traitons des données personnelles et les mesures de protection mises en place pour les transferts internationaux de données personnelles;</li>
                      <li>les critères utilisés pour déterminer la période pendant laquelle nous stockons les données personnelles;</li>
                      <li>et les droits à la vie privée qui s’appliquent aux voyageurs pour lesquels Plusgrade traite des données personnelles pour le compte de ses partenaires commerciaux, y compris le droit de déposer une plainte auprès de l’autorité compétente en matière de protection de la vie privée.</li>
                    </ul>
                  </div>
                }
              />
          {/** Question # 2 */}
              <QuestionSection 
                question = {"Quelles données personnelles Plusgrade traite-t-elle?"}
                answer={
                  <div>
                    <p>
                    En tant que fournisseur tiers de services aux entreprises de l’industrie du voyage (c.-à-d., les « partenaires commerciaux »de Plusgrade), Plusgrade reçoit généralement des données personnelles qui ont été initialement divulguées par les voyageurs à ses partenaires commerciaux dans le cadre d’une réservation ou d’un achat de voyage, dans le but de fournir les Services à ses partenaires commerciaux conformément au contrat de service applicable.</p>

                    <p>Plusgrade peut également recevoir de ses partenaires commerciaux des informations concernant les demandes de service des voyageurs (par exemple, les préférences de repas en vol, la classe de voyage). Lorsque ces informations sont traitées par Plusgrade et sont associées à une personne, elles sont traitées comme des données personnelles aux fins de la présente Politique sur la protection des renseignements personnels.</p>
<p>Nous recueillons auprès de nos partenaires commerciaux, prospects et visiteurs du Site des informations qui sont saisies sur notre Site ou qui nous sont envoyées par voie électronique, par exemple lorsque des partenaires commerciaux achètent nos Services ou lorsque les visiteurs de notre Site demandent plus d’informations. Nous pouvons également collecter des données personnelles que vous nous fournissez lorsque vous assistez à l’un de nos événements, lors d’appels téléphoniques avec des représentants commerciaux ou lorsque vous contactez le service client.</p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <ol>
                      <li>
                      Données personnelles concernant les voyageurs :
                        <p>
                        Nous traitons les données personnelles fournies par les voyageurs dans le cadre d’une réservation ou d’un achat de services de voyage auprès d’entreprises de l’industrie du voyage (c.-à-d. les « partenaires commerciaux de Plusgrade), au nom de nos partenaires commerciaux. Cela inclut ou peut inclure les catégories de données personnelles suivantes:</p>
                        <ul>
                          <li>
                          les coordonnées, qui peuvent inclure le nom et l’adresse courriel;
                          </li>
                          <li>
                          les informations d’authentification, qui peuvent inclure des informations sur le passeport et le numéro du programme de fidélisation;</li>
                          <li>
                          les préférences liées au voyage et les informations sur le niveau de fidélisation;
                          </li>
                          <li>
                          les communications avec nos partenaires commerciaux, y compris les communications par courrier électronique;
                          </li>
                          <li>
                          les données de localisation, y compris l’aéroport ou le port de départ et de destination;
                          </li>
                          <li>
                          les identifiants en ligne, y compris l’adresse IP.
                          </li>
                        </ul>
                      </li>

                      <li>
                      Données personnelles concernant les partenaires commerciaux, les prospects, les visiteurs du site:
                      <p>
                        <strong className="italized">Données personnelles que vous nous fournissez.</strong> Nous collectons les informations que vous saisissez sur notre Site ou que vous nous envoyez par voie électronique, par exemple, lorsque vous demandez des informations, y compris une démonstration de produit, lorsque vous vous inscrivez à un webinaire ou à un autre événement, ou lorsque vous vous abonnez à des listes de diffusion. Bien que le type de données que nous collectons dépende de la nature de la demande, il s’agit généralement de votre nom et de vos coordonnées, des informations de votre entreprise et de votre numéro de téléphone. Nous pouvons également collecter des données personnelles que vous nous fournissez lorsque vous assistez à l’un de nos événements (telles que vos coordonnées), lors d’appels téléphoniques avec des représentants commerciaux ou lorsque vous contactez notre service client.
                      </p>
                      <span className="italized">Données collectées automatiquement.</span>
                      <p>
                        <strong className="italized">Données de journal.</strong> Lorsque vous visitez notre Site, nos serveurs enregistrent des informations (« données de journal »), y compris des informations que votre navigateur envoie automatiquement chaque fois que vous visitez le Site. Ces données de journal comprennent votre adresse de protocole Internet (« IP ») (à partir de laquelle nous identifions le pays à partir duquel vous vous connectez au moment où vous visitez le Site), le type et les paramètres du navigateur, la date et l’heure de votre demande.</p>
                      <p>
                        <strong className="italized">Témoins.</strong> Lorsque vous visitez le Site, nous et nos prestataires de services agissant en notre nom collectons automatiquement des informations vous concernant à travers des témoins (petits fichiers texte placés sur votre appareil).
                      </p>
                      </li>
                    </ol>
                  </div>

                }
              />
              {/** Question # 3 */}
              <QuestionSection 
                question = {"D’où Plusgrade obtient-elle les données personnelles?"}
                answer={
                  <ol>
                    <li>
                    Données personnelles concernant les voyageurs :
                      <p>Plusgrade obtient les données personnelles fournies par les voyageurs dans le cadre d’une réservation ou d’un achat de services de voyage auprès d’entreprises de l’industrie du voyage (c.-à-d les « partenaires commerciaux » de Plusgrade), dans le but de permettre aux voyageurs de participer aux surclassements de réservation et aux services connexes fournis par Plusgrade conformément au contrat de service applicable.</p>
                    </li>
                    <li>
                    Données personnelles concernant les partenaires commerciaux, les prospects, les visiteurs du site:
                      <p>Plusgrade obtient les données personnelles qu’elle traite, stocke et divulgue lorsque ces données lui sont fournies ou collectées comme décrit dans le paragraphe « Quelles données personnelles Plusgrade traite-t-elle? ».</p></li>
                  </ol>
                }
              />
              {/** Question # 4 */}
              <QuestionSection 
                question = {"Quelles est la base juridique ainsi que les finalités pour lesquelles Plusgrade traite les données personnelles?"}
                answer={
                  <div>
                    <p>
                    Nous utilisons les informations des voyageurs pour fournir les Services aux entreprises de l’industrie du voyage avec lesquelles nous travaillons (c.-à-d les « partenaires commerciaux » de Plusgrade). Nous utiliserons également ces informations dans le but d’améliorer les caractéristiques du produit ou de fournir des améliorations de service et soutenir nos partenaires commerciaux dans leur programme actuel et leur croissance continue conformément à notre accord de service. L’utilisation des données personnelles d’une personne sera limitée aux fins pour lesquelles elles ont été collectées par nos partenaires commerciaux - pour fournir les surclassements de réservation et les services connexes - tel que convenu dans notre accord de service avec nos partenaires commerciaux. Nos partenaires commerciaux sont responsables de s’assurer qu’ils obtiennent légalement des données personnelles des voyageurs. Comme Plusgrade fournit la technologie sur laquelle nos partenaires commerciaux fournissent des services aux voyageurs, lorsque les voyageurs souhaitent modifier leurs options concernant leurs données personnelles, Plusgrade traitera ces souhaits au nom de ses partenaires commerciaux et conformément à leurs instructions. Nous utilisons les données personnelles de nos partenaires commerciaux, prospects et visiteurs du Site au besoin pour répondre aux demandes concernant nos services, remplir le contrat concerné, envoyer des courriels promotionnels et d’autres communications (tant que vous acceptez de recevoir ces communications) et au besoin pour nos intérêts légitimes dans le traitement des données. Lorsque la loi applicable l’exige, nous obtenons votre consentement pour la collecte, l’utilisation et la divulgation de vos informations personnelles aux fins énoncées dans la présente Politique sur la protection des renseignements personnels.</p></div>
                }

                learnMoreContent={
                  <div>
                    <ol>
                      <li>
                      Données personnelles concernant les voyageurs:
                        <p>
                        <strong>Pour vous fournir les Services et répondre à vos demandes.</strong> Lorsque vous demandez des informations concernant les Services (par exemple, lorsque vous demandez une démonstration ou vous nous demandez de vous envoyer des offres ou des informations sur les prix), ou lorsque vous vous inscrivez à un webinaire ou à un événement, nous utiliserons vos coordonnées pour répondre à votre demande. Pour les personnes concernées par les données résidentes de l’UE, une telle utilisation est nécessaire pour répondre à votre demande ou la mettre en œuvre.

                        Nous utilisons les données relatives aux comptes fournies par nos partenaires commerciaux dans le cadre de l’achat, de l’inscription, de l’utilisation ou du soutien du compte du partenaire commercial (telles que les noms d’utilisateur, l’adresse courriel et les informations de facturation) pour vous donner accès aux Services et/ou au Site, pour vous contacter concernant votre utilisation des Services et/ou du Site ou pour vous informer des modifications importantes apportées aux Services et/ou au Site. Pour les personnes concernées par les données résidentes de l’UE, une telle utilisation est nécessaire à l’exécution du contrat entre vous et nous.
</p>

<p><strong>À des fins de marketing.</strong> Nous utiliserons votre adresse courriel pour vous envoyer des informations concernant les nouveaux produits et services, les événements à venir ou d’autres promotions. Vous pouvez refuser de recevoir ces courriels en suivant les instructions contenues dans chaque courriel promotionnel que nous vous envoyons. Nos représentants commerciaux peuvent également utiliser votre numéro de téléphone pour vous contacter directement par téléphone, dans le but de promouvoir ces nouveaux produits et services, des événements à venir ou d’autres promotions.</p>
<p>Lorsque la loi applicable l’exige (par exemple, si vous êtes une personne concernée par les données résidente de l’UE ou du Canada), nous ne vous enverrons des informations marketing par courriel ou par courrier, et/ou ne vous contacterons par téléphone que si vous nous y autorisez au moment où vous nous fournissez vos données personnelles (dans ce cas, votre consentement constituera notre base légale pour le traitement de vos données personnelles). Lorsque vous nous donnez votre consentement à être contacté à des fins de marketing, vous avez le droit de retirer votre consentement à tout moment en suivant les instructions pour « refuser » de recevoir des communications marketing dans chaque courriel marketing que nous vous envoyons. Si vous ne souhaitez plus recevoir de futures communications marketing ou si vous souhaitez que votre nom soit supprimé de nos listes de diffusion ou d’appel, veuillez nous contacter à l’adresse <a href="mailto:dpo@plusgrade.com">dpo@plusgrade.com</a>. </p>

<p>. Veuillez noter que si vous vous désabonnez des communications marketing, nous pouvons toujours vous contacter au sujet de questions liées à nos Services et pour répondre à vos demandes.
</p>

<p><strong>Analyse, administration, soutien, amélioration de l’utilisation du Site et des Services.</strong>Nous utilisons les données relatives à votre utilisation et à votre interaction avec le Site et les Services, y compris les informations que nous obtenons par le biais des témoins, pour analyser, administrer, soutenir et améliorer votre accès et votre utilisation du Site et des Services. Nous pouvons également compiler, dépersonnaliser et/ou rendre globales vos données personnelles et d’autres données et utiliser ces données dépersonnalisées et/ou globales à des fins commerciaux, y compris les partager avec des sociétés affiliées et des partenaires commerciaux. Ces informations globales ne vous identifient pas. </p>
<p>Pour les personnes concernées par les données résidentes de l’UE, cette utilisation de vos données personnelles est nécessaire pour nos intérêts légitimes à comprendre comment vous utilisez le Site, nos services et à améliorer votre expérience sur le Site. Vous avez le droit de vous opposer au traitement de vos données personnelles sur la base de notre intérêt légitime, comme décrit dans la section « Quels sont les droits à la vie privée des personnes concernant leurs propres données personnelles? » ci-dessous.</p>
                      
                      <p>Veuillez consulter la section « Quels sont les droits à la vie privée des personnes concernant leurs propres données personnelles? » ci-dessous pour obtenir des informations sur les droits que vous pourriez avoir en ce qui concerne les données personnelles que nous détenons à votre sujet.</p>
                      </li>
                    </ol>
                  </div>
                }
              />
              {/** Question # 5 */}
              <QuestionSection 
                question = {"Combien de temps Plusgrade conserve-elle les données personnelles?"}
                answer={
                  <div>
                    <p>
                    Plusgrade conserve les données personnelles des voyageurs pendant la durée de notre accord de service avec nos partenaires commerciaux, à moins qu’une période de conservation plus longue ou plus courte ne soit demandée, requise ou autorisée par les lois applicables.</p>
                    <p>
                    Nous conservons les autres données personnelles soumises à la présente Politique sur la protection des renseignements personnels uniquement pour une durée qui est raisonnablement nécessaire aux fins décrites dans la présente Politique, ou pour la durée requise par la loi, la durée la plus longue étant retenue.</p>
                  </div>
                }
              />
              {/** Question # 6 */}
              <QuestionSection 
                question = {"Vers quels pays Plusgrade transfère-t-elle des données personnelles dans le cadre de l’offre de ses services?"}
                answer={
                  <div>
                    <p>
                    Dans le cadre de notre offre de services et afin de respecter nos obligations contractuelles, nous transférons des données personnelles de l’UE vers le Canada, les États-Unis et les pays de résidence des entreprises de l’industrie du voyage (c.-à-d les « partenaires commerciaux » de Plusgrade). Nous pouvons également sous-traiter le traitement ou le partage des données personnelles des voyageurs à des tiers situés dans des pays autres que leur pays d’origine. Par conséquent, vos informations personnelles peuvent être transférées vers une compétence autre que la compétence dans laquelle nous sommes situés et dans laquelle vous résidez. Dans ces circonstances, vos informations personnelles peuvent être soumises à la loi de cette compétence étrangère et peuvent ainsi devenir accessibles aux agences gouvernementales, aux tribunaux et à l’application de la loi de cette compétence.</p>
                    <p>
                    Sous réserve de ce qui précède, nous nous efforçons de protéger ces informations lors des transferts transfrontaliers et de respecter les règles relatives au transfert transfrontalier de données de la manière suivante:</p>
                    <ul>
                      <li>
                      La Commission européenne a constaté que le Canada offre un niveau de protection adéquat pour les données personnelles transférées depuis l’UE. Cela signifie que les données personnelles peuvent légalement circuler de l’UE vers Plusgrade au Canada sans être soumises à des règles de transfert;</li>
                      <li>
                      Pour respecter les restrictions sur les transferts de données entre l’UE et les États-Unis, nous utilisons des « clauses contractuelles types » (également appelées « clauses types ») approuvées par la Commission européenne.</li>
                    </ul>
                    <p>
                    Si vous souhaitez obtenir plus d’informations concernant les mesures de protection que Plusgrade utilise lors des transferts internationaux de données personnelles, veuillez nous contacter comme indiqué dans la section « Comment contacter Plusgrade? » ci-dessous.</p>
                  </div>
                }
              />
              {/** Question # 7 */}
              <QuestionSection 
                question = {"Est-ce que Plusgrade partage des données personnelles avec des tiers?"}
                answer={
                  <div>
                    <p>
                    Plusgrade partage les données personnelles des voyageurs avec des tiers uniquement dans la mesure nécessaire à l’exécution de notre contrat de service avec les entreprises de l’industrie du voyage avec lesquelles nous travaillons (c.-à-d les « partenaires commerciaux de Plusgrade) et conformément aux instructions de nos partenaires commerciaux, si nous vendons ou transférons une quelconque partie de notre activité, ou si la loi applicable l’exige.</p>
                    <p>
                    Plusgrade partage les données personnelles de ses partenaires commerciaux, prospects et visiteurs du site avec des tiers qui soutiennent ses opérations commerciales ou tel que requis ou permis par la loi applicable.</p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                    Plusgrade peut partager des données personnelles avec des tiers dans les circonstances décrites ci-dessous:
                      <ul>
                        <li>
                        Fournisseurs de services tiers: afin de fournir certains services à ses partenaires commerciaux, Plusgrade peut partager des données personnelles avec des tiers, par exemple: pour fournir des services de technologie de l’information; pour fournir des services de protection contre la fraude; pour gérer les transactions par carte de crédit; pour fournir un service client; ou pour fournir des services de marketing. Les fournisseurs de services ne reçoivent que les informations nécessaires à l’exécution de ces services et sont tenus contractuellement de protéger la confidentialité et la sécurité des informations que nous fournissons et de limiter l’utilisation des informations aux services fournis. </li>
                        <li>
                        Autorités gouvernementales ou autres: Plusgrade peut, sans préavis aux partenaires commerciaux, divulguer des données personnelles si cela est requis par la loi, par une ordonnance du tribunal, à la demande d’un autre gouvernement ou d’une autorité chargée de l’application de la loi, ou dans la conviction de bonne foi que la divulgation est autrement nécessaire ou conseillée, y compris, sans s’y limiter:
                          <ul>
                            <li>
                            pour protéger les droits ou les biens de Plusgrade ou de ses partenaires commerciaux;</li>
                            <li>
                            lorsque nous avons raison de croire que la divulgation des informations est nécessaire pour identifier, contacter ou intenter une action en justice contre une personne susceptible de causer une ingérence dans nos droits ou nos biens;</li>
                            <li>
                            quand quelqu’un d’autre pourrait être affecté par de telles activités.
                            </li>
                          </ul>
                        </li>
                        <li>
                        Transferts de l’entreprise: Si Plusgrade ou la quasi-totalité de ses actifs sont acquis, les données personnelles résidant dans nos bases de données seront très probablement également transférées dans le cadre d’une telle acquisition. Dans le cas de toute autre transaction ou événement important tel qu’une fusion, un financement, une réorganisation, une liquidation ou une insolvabilité, les autres parties à la transaction et leurs conseillers professionnels peuvent avoir besoin d’avoir accès aux bases de données de Plusgrade dans le cadre du processus de diligence raisonnable. Dans ce cas, Plusgrade ne fournirait un tel accès que dans le cadre d’un accord de confidentialité. Au terme de la transaction ou de l’événement, les données personnelles seraient transférées à l’entreprise ayant droit et seraient utilisées aux fins pour lesquelles elles ont été collectées.</li>
                      </ul>
                    </p>
                  </div>
                }
              />
              {/** Question # 8 */}
              <QuestionSection 
                question = {"Quels sont les droits à la vie privée les personnes ont-elles concernant leurs propres données personnelles?"}
                answer={
                  <div>
                    <p>
                    Selon l’endroit où vous résidez, vous pouvez avoir certains ou tous les droits en ce qui concerne le traitement de vos données personnelles comme indiqué ci-dessous. Les personnes concernées par les données résidant dans l’Union européenne (UE) ont tous ces droits (à ces fins, la référence à l’UE inclut également les pays de l’Espace économique européen que sont l’Islande, le Liechtenstein et la Norvège et, le cas échéant, la Suisse). Les personnes concernées par les données résidant au Canada ont le droit de demander l’accès à leurs propres données personnelles et/ou à leur correction, de retirer leur consentement à l’utilisation de leurs données personnelles (sauf dans des circonstances limitées, y compris les exigences légales ou réglementaires ou en raison de vos obligations contractuelles avec nous), le droit de déposer une plainte auprès de l’autorité de protection des données et, au Québec, le droit de demander l’effacement lorsque les données personnelles sont obsolètes ou ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées.</p><ol>
                      <li>
                      Droits des voyageurs:
                        <p>
                        Les voyageurs ont le droit de soumettre les demandes suivantes concernant leurs données personnelles aux entreprises de l’industrie du voyage (c.-à-d. les « partenaires commerciaux » de Plusgrade) auxquels les voyageurs ont précédemment fourni leurs données personnelles, sous réserve de la loi applicable:</p><ul>
                          <li>
                          demande d’accès et/ou de correction de leurs propres données personnelles;
                          </li>
                          <li>
                          demande d’effacement de leurs propres données personnelles;        
                          </li>
                          <li>
                          demande auprès de Plusgrade de cesser le traitement ou de restreindre le traitement de leurs propres données personnelles;</li><li>
                          demande auprès de Plusgrade de leur fournir leurs données personnelles dans un format structuré, couramment utilisé et lisible par machine et de transmettre ces données à une autre organisation.</li>
                        </ul>
                        <p>
                        Sur notification de la part de nos partenaires commerciaux de la demande d’un voyageur d’exercer ses droits, nous autoriserons ces demandes pour assurer la conformité de nos partenaires commerciaux aux exigences réglementaires applicables conformément au contrat de service avec le partenaire commercial concerné.</p></li>

                      <li>
                       Droits des partenaires commerciaux, prospects, visiteurs du site:
                        <p>
                        Sous réserve de la loi applicable, vous disposez des droits suivants concernant vos données personnelles:</p>
                        <ul>
                          <li>
                            <strong>droit d’accès:</strong> Si vous nous le demandez, nous confirmerons si nous traitons vos données personnelles et, dans l’affirmative, nous vous fournirons une copie de ces données personnelles ainsi que de certaines autres informations. Si vous avez besoin de copies supplémentaires, nous pouvons facturer des frais raisonnables;</li>
                          <li>
                            <strong>droit de rectification:</strong> Si vos données personnelles sont inexactes ou incomplètes, vous avez le droit de nous demander de les corriger ou de les compléter. Si nous avons partagé vos données personnelles avec d’autres entités, nous les informerons de la correction dans la mesure du possible. Si vous nous le demandez, et lorsque cela est possible et légal, nous vous indiquerons également avec qui nous avons partagé vos données personnelles afin que vous puissiez les contacter directement;
                          </li>
                          <li>
                          <strong>droit à l’effacement:</strong> Vous pouvez nous demander de supprimer vos données personnelles, par exemple lorsque vous retirez votre consentement. Si nous avons partagé vos données avec d’autres entités, nous les informerons de l’effacement dans la mesure du possible. Si vous nous le demandez, et lorsque cela est possible et légal, nous vous indiquerons également avec qui nous avons partagé vos données personnelles afin que vous puissiez les contacter directement;</li>
                          <li>
                          <strong>droit de restreindre le traitement:</strong> Vous pouvez nous demander de restreindre ou de « bloquer » le traitement de vos données personnelles dans certaines circonstances, par exemple lorsque vous contestez l’exactitude des données ou que vous vous opposez à ce que nous les traitions. Nous vous en informerons avant de lever toute restriction sur le traitement. Si nous avons partagé vos données personnelles avec d’autres entités, nous les informerons de la restriction dans la mesure du possible. Si vous nous le demandez, et lorsque cela est possible et légal, nous vous indiquerons également avec qui nous avons partagé vos données personnelles afin que vous puissiez les contacter directement;</li>
                          <li>
                          <strong>droit à la portabilité des données:</strong> Vous avez le droit d’obtenir de notre part les données personnelles que vous avez consenties à nous donner ou qui nous ont été fournies si nécessaire dans le cadre de notre contrat avec vous. Nous vous communiquerons vos données personnelles dans un format structuré, couramment utilisé et lisible par machine. Vous pouvez les réutiliser ailleurs;</li>
                          <li>
                          <strong>droit d’opposition:</strong> Vous pouvez nous demander à tout moment de cesser de traiter vos données personnelles, et nous le ferons si nous nous appuyons sur un intérêt légitime pour traiter vos données personnelles (sauf si nous démontrons des motifs légitimes impérieux pour le traitement) ou si nous traitons vos données personnelles à des fins de marketing direct;</li>
                          <li>
                          <strong>droit de retirer son consentement:</strong> Si nous nous appuyons sur votre consentement pour traiter vos données personnelles, vous avez le droit de retirer ce consentement à tout moment. Cela n’affectera pas la légalité du traitement de vos données avant que nous ayons reçu l’avis que vous souhaitiez retirer votre consentement. Si vous choisissez de retirer votre consentement, lorsque ce retrait est disponible, nous pourrions ne pas être en mesure de vous fournir nos services;
                          </li>
                          <li>
                          <strong>droit de déposer une plainte auprès de l’autorité de protection des données: </strong> Si vous avez des préoccupations concernant nos pratiques en matière de protection des renseignements personnels, y compris la façon dont nous avons traité vos données personnelles, vous pouvez le signaler à l’autorité de protection des données qui est autorisée à entendre ces préoccupations.
                          </li>
                        </ul>
                      </li>
                    </ol>
                   
                  </div>
                }
              />
              {/** Question # 9 */}
              <QuestionSection 
                question = {"Comment Plusgrade protège-t-elle les données personnelles?"}
                answer={
                  <div>
                    <p>Plusgrade prendra des précautions raisonnables, y compris la mise en œuvre de procédures physiques, électroniques et administratives appropriées, pour protéger les données personnelles contre la perte, l’utilisation abusive et l’accès non autorisé, la divulgation, l’altération et la destruction. Bien que nous utilisions des mesures de protection techniques, nous ne pouvons pas garantir la sécurité des données personnelles transmises sur Internet.</p></div>
                }
                learnMoreContent={
                  <div>
                    <p>
                    Nous avons mis en place des mesures de sécurité strictes dans le cadre de nos services aux entreprises de voyages et d’hôtellerie avec lesquelles nous travaillons (c.-à-d les « partenaires commerciaux » de Plusgrade). Dans le but de protéger vos informations, nous maintenons des politiques et des pratiques qui fournissent un cadre pour notre traitement de vos informations personnelles, définissent les rôles et les responsabilités pour le traitement de vos informations à partir du moment où nous les collectons jusqu’à ce qu’elles soient détruites, et régissent notre processus interne de traitement des plaintes concernant la protection des données personnelles, y compris notre Politique de protection des données, notre Politique de sécurité Internet et du réseau et notre Politique de signalement des incidents. En particulier, ces politiques régissent les mesures que nous prenons pour protéger les ressources et les actifs de l’entreprise, y compris les données, l’architecture d’application, le code d’application, l’accès au réseau, l’accès à la localisation et l’accès au matériel et aux appareils, et garantissent la disponibilité et l’accès en temps opportun aux données personnelles en cas d’incident physique ou technique, ainsi que la mise en œuvre de processus internes pour tester et évaluer régulièrement l’efficacité des mesures de sécurité.</p> </div>
                }
              />
              {/** Question # 10 */}
              <QuestionSection 
                question = {"Comment cette politique sur la protection des renseignements personnels est-elle appliquée chez Plusgrade?"}
                answer={
                  <div>
                    <p>
                    Plusgrade suit un processus interne de surveillance dans le but d’assurer la conformité à la présente Politique sur la protection des renseignements personnels et vérifie périodiquement qu’il est exact, complet pour les informations destinées à être couvertes, affiché en évidence, complètement mis en œuvre et accessible, et conforme à la législation sur la vie privée et la protection des données applicable à Plusgrade.</p></div>
                }
                learnMoreContent={
                  <div>
                    <p>
                    Nous examinerons les informations contenues dans cette Politique sur la protection des renseignements personnels sur une base continue et nous nous assurerons qu’elles restent pertinentes pour les pratiques de l’organisation et conformes aux exigences réglementaires à venir en matière de vie privée et de protection des données. Occasionnellement, nous pouvons également, à notre discrétion, apporter des modifications à la présente Politique sur la protection des renseignements personnels.</p>
                    <p>
                    Lorsque ce sera fait, nous publierons sur notre site une nouvelle version de la Politique sur la protection des renseignements personnels reflétant ces changements. Si nous apportons des modifications importantes à cette politique sur la protection des renseignements personnels, nous fournissons aux personnes concernées un avis bien en vue, le cas échéant, dans les circonstances et conformément à la loi applicable. Ces avis pourront prendre la forme d’un courriel ou d’un avis bien en vue sur notre site web. L’utilisation continue de notre Site ou de nos services après cet avis constituera une acceptation des modifications. Si vous n’acceptez pas les modifications apportées à notre politique sur la protection des renseignements personnels, il vous incombera de cesser d’utiliser le site et nos services. La « Date d’entrée en vigueur » en haut de cette Politique sur la protection des renseignements personnels indiquera quand elle a été mise à jour pour la dernière fois.</p>
                  </div>
                }
              />
              {/** Question # 11 */}
              <QuestionSection 
                question = {"Comment communiquer avec Plusgrade?"}
                answer={
                  <div>
                    <p>
                    Plusgrade agit en tant que processeur de données pour le traitement des données personnelles des voyageurs pour le compte des entreprises de l’industrie du voyage (c.-à-d. les « partenaires commerciaux » de Plusgrade), conformément à notre contrat de service avec le partenaire commercial concerné.</p>
                    <p>
                    Nous agissons en tant que contrôleur des données pour le traitement des données personnelles concernant nos partenaires commerciaux, prospects et visiteurs du Site aux fins décrites dans la présente Politique sur la protection des renseignements personnels.</p>
                    <p>
                    Les questions, commentaires ou plaintes concernant notre Politique sur la protection des renseignements personnels ou nos pratiques en matière de traitement des données peuvent être envoyés par courrier ou par courrier électronique directement au délégué à la protection des donnéesde Plusgrade :</p>
                    <a href="mailto:dpo@plusgrade.com">dpo@plusgrade.com</a>
                    <p>
                    Les questions, commentaires ou plaintes concernant notre politique sur la protection des renseignements personnels ou les pratiques de collecte et de traitement des données concernant les personnes concernées par les données résidentes de l’UE peuvent être envoyés par courrier ou par courrier électronique au Représentantde Plusgrade dans l’UE:</p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                    Si vous estimez ne pas avoir reçu de réponse opportune ou satisfaisante de notre part à votre question ou à votre plainte, veuillez contacter l’autorité de protection des données applicable ci-dessous:</p>
                    <a href="https://edpb.europa.eu/about-edpb/who-we-are/legacy-art-29-working-party_fr">Autorités de protection des données en UE</a>
                    <a href="https://www.edoeb.admin.ch/edoeb/fr/home/deredoeb.html">Préposé fédéral à la protection des données et à la transparence (PFPDT) en Suisse</a>
                    <a href="https://www.priv.gc.ca/fr">Commissariat à la protection de la vie privée du Canada</a>
                  </div>
                }
              />
            </div>
          </Copy>
        </PrivacyPolicy>
      </Content>
  </Layout>
  );
  
}

export default PrivacyPage;

const QuestionSectionContainer = styled.div`
font-family: ${font.family.sherika};
pointer-events: none;
p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 10px;
  font-size: 24px;
  font-weight: 700;
  color: ${brand.colors.pg_blue};
  margin-bottom: 10px;
  pointer-events: all;
  cursor: pointer;
  border-bottom: 1px solid #BDC4D1;
  padding-bottom: 10px;

  .expand-icon {
    color: #BDC4D1;
    font-size: 40px;
  }
}

.reveal-container {
  max-height: 0;
  height: auto;
  overflow: hidden;
  border: none;
  width: 95%;
  margin: 0 auto 20px;
   p {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: ${brand.colors.pg_primary_dark};
    margin: 10px 0;
  }

  .italized {
    font-style: italic;
    font-size: 20px;
  }

  a {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
    pointer-events: all;
  }

  ol {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_blue};
      list-style: none;
      font-weight: 800;
      margin: 10px 0;
      font-size: 20px;

      ul > li {
        color: ${brand.colors.pg_primary_dark};
        font-weight: 400;
        font-size: 20px;
      }

      ul > li:before {
        content: '';
      }
    }

    li:before {
      content: counter(list, lower-alpha) ") ";
      counter-increment: list;
      font-size: 20px;
    }
  }
  ul {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_primary_dark};
      list-style: circle;
    }

    ul > li {
      list-style: square;
    }
  }
}

${props => props.open === true && css`
  p {
    border: none;
  }
  .reveal-container {
    max-height: 6000px;
  }
`}

`;

const LearnMoreSection = styled.div`
  .learn-more-clickable {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
  }
  .learn-more-expandable {
    color: ${brand.colors.pg_primary_dark};
    height: auto;
    max-height: 0;
    width: 95%;
    margin: auto;

    a {
      display: block;
      pointer-events: all;
      cursor: pointer;
      text-decoration: underline;
      color: ${brand.colors.pg_blue};
    }
  }

  ${props => props.open === true && css`
    .learn-more-expandable {
      max-height: 6000px;
    }
  `}
`;



{/**
Question Section Component
Accepts
- Question prop = Clickable Header section question
- Answer Prop = Content of dropdown, accepts HTML. Check styled component here carefully as adding p, ul, or ol are pre-styled.
- learnMoreContent = Dropdown content of the 'learn more' section
*/}
const QuestionSection = ({question, answer, learnMoreContent}) => {

const [FAQOpen, setFAQSection] = useState(false);
const [learnMoreOpen, setLearnMore] = useState(false); 

  const pageQuery = useStaticQuery(graphql`
  query{
    plusIconGrey: file(base: {eq: "plus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    minusIconGrey: file(base: {eq: "minus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

  }
`);

let plusIcon = <span className={"expand-icon"}>+</span>
let minusIcon = <span className={"expand-icon"}>-</span>
const openClose = () => {
  if (FAQOpen && learnMoreOpen){
    setLearnMore(false);
  }
  setFAQSection(!FAQOpen); 
}

const expandCompressLearnMore = () => {
  setLearnMore(!learnMoreOpen);
}
  return(
    <QuestionSectionContainer open={FAQOpen}>
      <p onClick={openClose}>{question} {FAQOpen ? minusIcon : plusIcon}</p>
      <div className={"reveal-container"}>
        {answer}
      {
        learnMoreContent ? 
        <LearnMoreSection open = {learnMoreOpen}>
          <p className={"learn-more-clickable"} onClick={expandCompressLearnMore}>En savoir plus</p>
          <div className={"learn-more-expandable"}>
            {learnMoreContent}
          </div>
      </LearnMoreSection>
      :
      <></>
      }
      </div>
    </QuestionSectionContainer>
  );
}
